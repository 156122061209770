<template>
   <div class="table-content table-responsive">
      <table class="table border-0 w-max-content">
         <tbody>
            <especificacao v-for="(especificacao, index) in comparacoes" :key="index" :especificacao="especificacao" :index="index" :view="'Comparacao'"
               @comparar="addComparacao($event)" @removerComparacao="removerComparacao($event)" />
         </tbody>
      </table>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import especificacao from '@/components/produtos/Especificacao.vue'

export default {
   name: 'Comparacao',
   props: ['codigosIniciais', 'metodo'],
   data : function () {
      return {
         comparacoes: [],
         codigos: this.codigosIniciais
      }
   },
   computed: {
		... mapState({
			codigosComparacao: state => state.codigosComparacao,
			urlRest: state => state.urlRest,
         logado: state => state.logado
		})
	},
   watch: {
      codigosIniciais : function (newVal, oldVal) {
         if (Array.isArray(newVal) && JSON.stringify(oldVal) != JSON.stringify(newVal)) {
            this.codigos = newVal
         }
      },
      codigos : function (newVal, oldVal) {
         if (Array.isArray(newVal) && JSON.stringify(oldVal) != JSON.stringify(newVal)) {
            this.compararProduto()
         }
      }
   },
   components: {
      especificacao
   },
   methods: {
      addComparacao : function (codigo) {
         if (this.metodo == 'state') {
            this.$store.dispatch('compararProdutos', codigo)
         }

         this.codigos.push(codigo)
         this.compararProduto()
      },
      removerComparacao : function (codigo) {
         if (this.metodo == 'state') {
            this.$store.dispatch('compararProdutos', codigo)
         }

         this.codigos.splice(this.codigos.findIndex(cod => cod == codigo), 1)
         this.compararProduto()
      },
      compararProduto : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'site/getProdutoComparacao',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.codigos

			}).then(response => {
            this.comparacoes = response.data
            this.comparacoes.forEach(item => {
               if (item.rotulo == 'Fotos') {
                  item.valores.push({'nome': 'Pesquisa-Comparacao'})
               } else {
                  item.valores.push('Campo-Vazio')
               }
            });
			}).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               })
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      this.codigos = JSON.parse(JSON.stringify(this.codigosIniciais))

      if (Array.isArray(this.codigosIniciais)) {
         this.compararProduto()
      }
   }
}

</script>