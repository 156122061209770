<template>
   <tr v-if="view == 'Especificacoes-Baixar'" class="row" :class="index % 2 == 0 ? 'bg-secondary' : ''">
      <td class="col"><a href="javascript:;" class="weight-500 color-theme" @click="download"><i class="fas fa-download font-12 me-2"></i>Baixar ficha técnica</a></td>
   </tr>

   <tr v-else-if="view == 'Especificacoes' && especificacao.rotulo != 'Fotos'" class="row" :class="index % 2 == 0 ? 'bg-secondary' : ''">
      <td>{{ especificacao.rotulo }}</td>
      <td class="col weight-300" v-for="(valor, index) in especificacao.valores" :key="index">{{ valor == '' ? '-' : valor }}</td>
   </tr>
   
   <tr v-else-if="view == 'Compativeis'" class="row" :class="index % 2 == 0 ? 'bg-secondary' : ''">
      <td>Cód. {{ especificacao.codigo == null ? '-' : especificacao.codigo }}</td>
      <td class="col weight-300">{{ especificacao.nome == null ? '-' : especificacao.nome }}</td>
   </tr>

   <tr v-else-if="view == 'Comparacao' && especificacao.rotulo == 'Fotos'" class="row grey-bg">
      <td class="bg-transparent">&nbsp;</td>
      <td class="bg-white wpx-275 weight-300" v-for="(valor, index) in especificacao.valores" :key="index">
         <div class="w-100 h-100 pt-50" v-if="valor.nome == 'Pesquisa-Comparacao'">
            <label class="weight-500 font-14 mb-15 mt-50">COMPARAR COM...</label>
            <autocomplete :search="searchProdutos" :debounce-time="1000" :get-result-value="formatLabel" @submit="comparar" :data-position="'below'" placeholder="Nome ou código"></autocomplete>
         </div>
         <div class="w-100 h-100 position-relative" v-else>
            <i class="far fa-trash text-danger" @click="removerComparacao(valor.codigo)" v-if="view == 'Comparacao' && especificacao.valores.length > 2"></i>
            <img :src="valor.foto == null ? '' : valor.foto" @error="imageError" />
            <h1 class="my-2 text-center"><router-link :to="'/produto/'+ valor.codigo">{{ valor.nome }}</router-link></h1>
         </div>
      </td>
   </tr>
   <tr v-else-if="view == 'Comparacao' && especificacao.rotulo == 'Preço'" class="row bg-secondary">
      <td>{{ especificacao.rotulo }}</td>
      <td class="wpx-275 weight-300 p-0" v-for="(valor, index) in especificacao.valores" :key="index" :class="valor == 'Campo-Vazio' ? 'grey-bg' : ''">
         <h1 class="mt-2 mb-1" v-if="valor != 'Campo-Vazio'">{{ valor == null ? '-' : 'R$ '+ parseFloat(valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</h1>
      </td>
   </tr>
   <tr v-else-if="view == 'Comparacao'" class="row" :class="index % 2 == 1 ? 'bg-secondary' : ''">
      <td>{{ especificacao.rotulo }}</td>
      <td class="wpx-275 weight-300" v-for="(valor, index) in especificacao.valores" :key="index" :class="valor == 'Campo-Vazio' ? 'grey-bg' : ''">
         {{ valor == 'Campo-Vazio' ? '' : valor == '' ? '-' : valor }}
      </td>
   </tr>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Especificacao',
   props: ['especificacao', 'index', 'view'],
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      download : function () {
         this.$emit("download")
      },
      formatLabel : function (result) {
         return 'Cód. '+ result.codigo +' - '+ result.nome
      },
      searchProdutos : function (input) {
         if (input.length < 3) { return [] }

         return this.$axios({
				method: 'get',
				url: this.urlRest +'site/searchProdutosLabel',
            params: {
               valor: input,
               pecas: false
            }
			}).then(response => {
            return response.data
			})
      },
      comparar : function (result) {
         this.$emit('comparar', result.codigo)
      },
      removerComparacao : function (codigo) {
         this.$emit('removerComparacao', codigo)
      }
   }
}

</script>

<style scoped>

*:not(i) {
   text-decoration: none;
   color: inherit;
   font-family: 'Rubik', sans-serif;
}

img {
	aspect-ratio: 1 / 1;
	object-fit: contain;
   padding: 10px;
   height: 200px;
   width: 200px;
}

.row {
   margin: 0;
   flex-wrap: nowrap;
}

td {
   padding: 8px 12px !important;
   border: none !important;
   border-right: 1px solid #d6dce1 !important;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14.5px !important;
   min-width: 250px;
}

td:first-child {
   width: 185px;
   min-width: 185px;
   justify-content: start;
   text-align: start;
}

.wpx-275 {
   width: 250px;
   max-width: 100%;
}

@media (min-width: 768px) {
   td {
      padding: 8px 24px !important;
   }
   
   td:first-child {
      width: 250px;
      min-width: 250px;
   }

   .wpx-275 {
      width: 275px;
      max-width: 100%;
   }
}

td:last-child {
   border-right: none !important
}

h1 {
   font-size: 14px;
}

.fa-trash {
   position: absolute;
   top: 20px;
   right: 20px;
   cursor: pointer;
}

.impressao .fa-trash {
   display: none;
}

.impressao h1 {
   font-size: 11.5px;
}

.impressao img {
   width: 120px;
   height: 120px;
}

.impressao .wpx-275,
.impressao td {
   padding: 8px 12px !important;
   font-size: 11.5px !important;
   width: auto !important;
   flex: 1;
   min-width: 145px !important;
   padding: 8px 12px !important;
}

.impressao td:first-child {
   max-width: 145px !important;
   width: 145px !important;
}

</style>