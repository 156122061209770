<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-25 pb-10 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper d-flex align-items-center">
               <nav class="col" aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Comparação</li>
                  </ol>
               </nav>
               <div class="w-max-content align-self-center" v-if="codigos.length > 0">
                  <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="limparComparacao">Limpar comparação</a>
               </div>
            </div>
         </div>
      </section>

      <!-- comparacao area start -->
      <section class="comparacao__area pt-10 pb-100 grey-bg">
         <div class="container" v-if="codigos.length > 0">
            <comparacao :codigosIniciais="codigos" :metodo="$route.params.codigo == 'state' ? 'state' : 'temp'" />
         </div>
         <div class="container" v-else>
            <div class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
               <div class="error__content text-center">
                  <div class="error__number">
                     <h1 class="mt-50 mb-50"><i class="far fa-random"></i></h1>
                  </div>
                  <span class="d-none d-sm-block">Nenhum produto adicionado na lista de comparação. Você deve adicionar alguns produtos para compará-los.</span>
                  <span class="d-none d-xl-block">Você vai encontrar um monte de produtos interessantes em nosso site.</span>
                  <h4 class="mt-3 mb-4">A lista de comparação está vazia.</h4>
                  <router-link to="/" type="button" class="t-y-btn t-y-btn-grey px-5">Voltar à loja</router-link>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import comparacao from '@/components/produtos/Comparacao.vue'

export default {
   name: 'ProdutoComparacao',
   data : function () {
      return {
         codigos: []
      }
   },
   computed: {
		... mapState({
         codigosComparacao : state => state.codigosComparacao
      })
   },
   components: {
      comparacao
   },
   methods: {
      limparComparacao : function () {
         this.codigosComparacao.forEach(codigo => {
            setTimeout(() => this.$store.dispatch('compararProdutos', codigo), 50);
         });
      }
   },
   created() {
      this.codigos = this.$route.params.codigo == 'state' ? this.codigosComparacao : this.$route.params.codigo
   }
}

</script>